import qs from 'qs';
import { batch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { I18nextProvider } from 'react-i18next';

import { fetchBalance } from './app/store/slices/balance/thunks';
import { useAppDispatch } from './app/store/store';
import { fetchExchanges } from './app/store/slices/exchanges/thunks';
import { fetchSubAccounts } from './app/store/slices/sub-accounts/thunks';
import { fetchUser, fetchUserKycStatus, fetchUserSettings } from './app/store/slices/user/thunks';

import { useAuth } from './shared/libs/hooks/use-auth';
import { setCookie } from './shared/libs/helpers/helper.lib';
import { connectWebSocket } from './shared/api/websocket.instance';
import { fetchPositionsPnl } from './pages/trading/model/thunks';
import { fetchTradeSettings } from './pages/settings/model/thunks';
import { fetchNotifications } from './widgets/notifications/model/thunks';
import { useStorageListener } from './shared/libs/hooks/use-storage-listener';

import i18n from './app/locales/i18n';
import Routers from './app/routers';

import 'src/app/styles/app.scss';

const App = () => {
  useStorageListener();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();

  const fetchBalancesAndConnectWebSocket = async () => {
    const { payload } = await dispatch(fetchBalance());

    if (payload) dispatch(connectWebSocket());
  };

  useEffect(() => {
    if (isAuth) {
      batch(() => {
        dispatch(fetchUser());
        dispatch(fetchUserKycStatus());
        dispatch(fetchTradeSettings());
        dispatch(fetchUserSettings());
        dispatch(fetchSubAccounts());
        dispatch(fetchExchanges());
        dispatch(fetchPositionsPnl());
        dispatch(fetchNotifications({ limit: 20, page: 1 }));
        fetchBalancesAndConnectWebSocket();
      });
    }
  }, [isAuth]);

  useEffect(() => {
    try {
      const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      const queryPartnerId = parsedParams.partner;

      const isPublic = location.pathname === '/trades';

      if (queryPartnerId && typeof queryPartnerId === 'string') {
        setCookie('partner', queryPartnerId);
        if (!isAuth && !isPublic) navigate('/auth/registration');
      }
    } catch (error) {
      console.debug('Error in useEffect when handling partner', error);
    }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <MantineProvider>
        <Routers />
      </MantineProvider>
    </I18nextProvider>
  );
};

export default App;
