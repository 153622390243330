import qs from 'qs';
import {
  memo, useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'src/app/store/store';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { EKycStatus } from 'src/app/store/slices/user/types';
import { selectAlert } from 'src/app/store/slices/alert/selectors';
import { join, getFromLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { ConnectionGaStepZero } from 'src/pages/profile/components/connection-ga-modal-steps/step-zero/connection-ga-step-zero';
import { selectLeveragesCalculationQueue } from 'src/pages/leverages/model/selectors';
import { clearCalculatorDetailMessage } from 'src/pages/leverages/model/slice';

import Aside from 'src/widgets/aside/aside';
import Header from 'src/widgets/header/header';
import Alerts from 'src/shared/ui/alert/alerts/alerts';
import useAlert from 'src/shared/libs/hooks/use-alert';
import Notifications from 'src/widgets/notifications/notifications';
import KycModaLView from 'src/entities/kyc-modal-viev/kyc-modal-view';
import useMobile from 'src/shared/libs/hooks/useMobile';
import DisconnectedWsModalContent from 'src/entities/disconnected-ws-modal-content/disconnected-ws-modal-content';
import AudioAlert from 'src/entities/audio-alert/audio-alert';

interface ILayoutProps {
  page?: string;
  children?: React.ReactNode;
}

const modalState = {
  isOpen: true,
};

const Layout = memo(({ children, page }: ILayoutProps) => {
  const showGA = getFromLocalStorage('showGA', null);
  const { t } = useTranslation();
  const { isOpen, setAlertMessage } = useAlert();

  const isMobile = useMobile();
  const dispatch = useAppDispatch();

  const [areShownNotifications, setAreShownNotifications] = useState(false);
  const [openedGAVerify, { open: openVerifyGA, close: closeVerifyGA }] = useDisclosure(false);
  const [openedKYCVerify, { open: openVerifyKYC, close: closeVerifyKYC }] = useDisclosure(false);
  const [openedWebSocketDisconnected, { open: openWebSocketDisconnected, close: closeWebSocketDisconnected }] = useDisclosure(false);

  const { disconnectedWebSocket } = useSelector(selectAlert);
  const { settings, kycStatus, user } = useSelector(selectUser);
  const leveregesCalculatorDetail = useSelector(selectLeveragesCalculationQueue);

  const handleOpenKYC = () => {
    if (!user) return;

    if (kycStatus === EKycStatus.CREATED && user && !user.is_verified) {
      openVerifyKYC();
    }
  };

  const handleCloseVerifyGA = () => {
    closeVerifyGA();
    handleOpenKYC();
    modalState.isOpen = false;
  };

  useEffect(() => () => {
    modalState.isOpen = true;
  }, []);

  useEffect(() => {
    if (modalState.isOpen) {
      const parsedParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const queryTabIndex = parsedParams?.tab;

      if (queryTabIndex === '1' || showGA === false) return;

      if (showGA === null && settings && !settings.is_active_ga) {
        openVerifyGA();
      }
    }
  }, [settings]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (leveregesCalculatorDetail) {
      const { detail, type } = leveregesCalculatorDetail;

      if (detail) {
        setAlertMessage(detail, type, true);

        timeout = setTimeout(() => {
          dispatch(clearCalculatorDetailMessage());
          clearTimeout(timeout);
        }, 3500);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [leveregesCalculatorDetail]);

  useEffect(() => {
    if (disconnectedWebSocket) openWebSocketDisconnected();
  }, [disconnectedWebSocket]);

  return (
    <div className={join('page', page)}>
      { areShownNotifications && (
        <Notifications setAreShownNotifications={setAreShownNotifications} />
      )}

      {isOpen && <Alerts />}

      <div className="content-wrapper">
        <Aside />

        <div className="content">
          <Header setAreShownNotifications={setAreShownNotifications} />
          {children}
        </div>
      </div>

      <Modal
        title={t('google_authenticator_connection')}
        style={{ overflow: 'unset' }}
        radius={16}
        size="md"
        opened={!isMobile && openedGAVerify}
        onClose={handleCloseVerifyGA}
        className="modal-custom-overflow"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <ConnectionGaStepZero nextStep={closeVerifyGA} closeModal={handleCloseVerifyGA} />
      </Modal>

      <Modal
        title={<span>{t('basic verification')}<br /> {t('not_passed')}</span>}
        style={{ overflow: 'unset' }}
        radius={16}
        size="md"
        opened={!isMobile && openedKYCVerify}
        onClose={closeVerifyKYC}
        className="modal-custom-overflow"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <KycModaLView closeModal={closeVerifyKYC} />
      </Modal>

      <Modal
        radius={16}
        size="md"
        opened={openedWebSocketDisconnected}
        onClose={closeWebSocketDisconnected}
        className="modal-custom-overflow hidden-title modal-border white-filter "
        closeOnClickOutside={false}
        closeOnEscape={false}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <DisconnectedWsModalContent />
      </Modal>

      <AudioAlert />
    </div>
  );
});

export default Layout;
