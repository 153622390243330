import className from 'classnames';
import { Drawer } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay, Tooltip } from '@mantine/core';

import {
  useCallback, useEffect, useState, useRef, useLayoutEffect,
} from 'react';

import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useAppDispatch } from 'src/app/store/store';
import { subscribeInstrument, unsubscribeInstrument } from 'src/app/store/slices/sub-accounts/thunks';
import { ReactComponent as ThreePointsIconSvg } from 'src/shared/assets/images/trading/three-points.svg';
import { ReactComponent as GridIconSvg } from 'src/shared/assets/images/trading/grid.svg';
import { ReactComponent as ListCionSvg } from 'src/shared/assets/images/trading/list.svg';
import { ReactComponent as ReduceIconSvg } from 'src/shared/assets/images/trading/reduce.svg';
import { ReactComponent as CloseXIconSvg } from 'src/shared/assets/images/trading/closeX.svg';
import { ReactComponent as CircleArrowRightIconSvg } from 'src/shared/assets/images/trading/circle-arrow-right.svg';
import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as CloseIconSvg } from 'src/shared/assets/images/trading/close.svg';
import {
  divideValues,
  toFixedDecimalPrecision,
  getFromLocalStorage, minusValues, multiplyValues, saveToLocalStorage, toFixedDecimal,
} from 'src/shared/libs/helpers/helper.lib';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import useMobile from 'src/shared/libs/hooks/useMobile';
import InputText from 'src/shared/ui/input/input-text/input-text';
import ButtonClose from 'src/shared/ui/button/button-close/button-close';

import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { BalanceType } from 'src/entities/balance-type';
import { ReduceSlider } from 'src/shared/ui/slider/reduce-slider';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { ETradingType, EStatus, Nullable } from 'src/shared/types/global-types';
import {
  ColumnOrderItem,
  Position, SortField,
  PositionCloseDetail,
  PositionToClosed,
} from '../../model/types';
import { positionsColumn, positionsTableColumns } from '../../constants';
import Empty from '../../../../entities/empty/empty';
import useSubAccount from '../../../../shared/libs/hooks/use-sub-account-name';
import {
  HiddenPositionsParams,
  PositionReduceParams, fetchHiddenPositions, fetchPositionReduce, fetchPositions, fetchPositionsClose, fetchPositionsCloseAll,
} from '../../model/thunks';
import {
  selectPositions, selectStatusClosePosition, selectStatusClosePositions, selectStatusHidden, selectStatusPositions,
  selectStatusReduce,
} from '../../model/selectors';
import { removePositionByIds } from '../../model/slice';
import styles from './open-positions.module.scss';

const OpenPositions = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { setAlertMessage } = useAlert();
  const { getSubAccountNameById } = useSubAccount();

  const isMobile = useMobile();
  const dispatch = useAppDispatch();

  const positions = useSelector(selectPositions);
  const hiddenStatus = useSelector(selectStatusHidden);
  const positionsStatus = useSelector(selectStatusPositions);
  const balanceVisible = useSelector(selectHiddenBalance);
  const closePositionsStatus = useSelector(selectStatusClosePositions);
  const closePositionStatus = useSelector(selectStatusClosePosition);
  const statusReducePosition = useSelector(selectStatusReduce);

  const [tableColumns, setTableColumns] = useState(positionsTableColumns);

  const optionsRef = useRef<HTMLDivElement>(null);
  const optionsButtonRef = useRef<HTMLButtonElement>(null);
  const reduceTdRef = useRef<HTMLDivElement>(null);
  const reduceButtonRef = useRef<Record<string, HTMLButtonElement>>({});

  const [positionCardType, setPositionCardType] = useState<'card' | 'list'>(getFromLocalStorage('positionCardType', 'card'));
  const [positionsType, setPositionsType] = useState<'open' | 'close'>('open');
  const [showReduce, setShowReduce] = useState(false);
  const [sortField, setSortField] = useState<SortField>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [prevPositionsLength, setPrevPositionsLength] = useState(0);
  const [reducePositions, setReducePositions] = useState({ x: 0, y: 0 });
  const [showOptionsPositions, setShowOptionsPositions] = useState(false);
  const [reducePositionId, setReducePositionId] = useState<Nullable<string>>(null);
  const [filterAccountType, setFilterAccountType] = useState<number | string>('');
  const [closePositionButtonById, setClosePositionButtonById] = useState<string[]>([]);

  // Reduce state
  const [reducePercent, setReducePercent] = useState(50);
  const [selectedPositions, setSelectedPositions] = useState<Position[]>([]);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(positionsColumn);
  const [showDrawer, setShowDrawer] = useState(false);
  const [reduceInputFocus, setReduceInputFocus] = useState(false);

  const [currentPosition, setCurrentPosition] = useState<Nullable<Position>>(null);

  const calculatePnl = (position: Position) => {
    if (position.instrument.price.ask === '0' || position.instrument.price.bid === '0') {
      return '0';
    }

    if (position.side === 'LONG') {
      const calculationResult = minusValues(position.instrument.price.bid, position.average_price);
      const result = multiplyValues(calculationResult, position.quantity, 2);

      if (result === '-0.00') {
        return '0.00';
      }
      return result;
    }

    if (position.side === 'SHORT') {
      const calculationResult = minusValues(position.average_price, position.instrument.price.ask);
      const result = multiplyValues(calculationResult, position.quantity, 2);

      if (result === '-0.00') {
        return '0.00';
      }
      return result;
    }
    return '0';
  };

  const calculatePnlInPercents = (position: Position) => {
    if (position.instrument.price.ask === '0' || position.instrument.price.bid === '0') {
      return '0';
    }

    if (position.side === 'LONG') {
      const divide = divideValues(position.instrument.price.bid, divideValues(position.average_price, '100'));
      return minusValues(divide, '100', 2);
    }

    if (position.side === 'SHORT') {
      const divide = divideValues(position.instrument.price.ask, divideValues(position.average_price, '100'));
      return minusValues('100', divide, 2);
    }
    return '0';
  };

  const sortedData: Position[] = Array.isArray(positions) ? positions.slice().sort((a, b) => {
    if (sortField === 'pnlInPercents') {
      const pnlA = parseFloat(calculatePnlInPercents(a));
      const pnlB = parseFloat(calculatePnlInPercents(b));

      if (pnlA < pnlB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (pnlA > pnlB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'pnlInCurrency') {
      const pnlA = parseFloat(calculatePnl(a));
      const pnlB = parseFloat(calculatePnl(b));

      if (pnlA < pnlB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (pnlA > pnlB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'average_price') {
      const averagePriceA = parseFloat(a.average_price);
      const averagePriceB = parseFloat(b.average_price);

      if (averagePriceA < averagePriceB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (averagePriceA > averagePriceB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'price') {
      const priceA = parseFloat(a.instrument.price.ask);
      const priceB = parseFloat(b.instrument.price.ask);

      if (priceA < priceB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (priceA > priceB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'leverage') {
      const leverageA = a.leverage;
      const leverageB = b.leverage;

      if (leverageA < leverageB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (leverageA > leverageB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'quantity') {
      const quantityA = parseFloat(a.quantity);
      const quantityB = parseFloat(b.quantity);

      if (quantityA < quantityB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (quantityA > quantityB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'quantity_dollars') {
      const quantityA = parseFloat(multiplyValues(a.average_price, a.quantity));
      const quantityB = parseFloat(multiplyValues(b.average_price, b.quantity));

      if (quantityA < quantityB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (quantityA > quantityB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'instrument_symbol') {
      const symbolA = a.instrument.symbol;
      const symbolB = b.instrument.symbol;

      if (symbolA < symbolB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (symbolA > symbolB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (sortField === 'exchange_name') {
      const exchangeNameA = a.exchange.name;
      const exchangeNameB = b.exchange.name;

      if (exchangeNameA < exchangeNameB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (exchangeNameA > exchangeNameB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }

    if (sortField) {
      if (a[sortField] < b[sortField]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
    return 0;
  }) : [];

  const filteredData = sortedData.filter((data) => {
    const isVisible = positionsType === 'close' ? data.is_visible === false : data.is_visible === true;
    const matchesAccountType = filterAccountType === '' || filterAccountType === 'hidden' || data.instrument.instrument_type === filterAccountType;
    return isVisible && matchesAccountType;
  });

  const [previousPositionsLength, setPreviousPositionsLength] = useState(filteredData.length);

  const incrementCount = useCallback(() => {
    setReducePercent((prev) => {
      if (prev < 100) {
        return prev + 1;
      }

      return prev;
    });
  }, []);

  const decrementCount = useCallback(() => {
    setReducePercent((prev) => {
      if (prev > 1) {
        return prev - 1;
      }

      return prev;
    });
  }, []);

  const handleOnChangeReduceValue = useCallback((value: number) => {
    setReducePercent(Math.min(Math.max(value, 1), 100));
  }, []);

  const handleChangeReducePercent = (value: number) => {
    const regexOnlyDigits = /^[0-9]*$/;

    if (regexOnlyDigits.test(String(value)) && value <= 100) {
      setReducePercent(Math.min(Math.max(value, 1), 100));
    }
  };

  const handleSort = (field: any) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleSelectPosition = (position: Position) => {
    if (selectedPositions.find((item) => item.id === position.id)) {
      setSelectedPositions((prev) => {
        prev = prev.filter((item) => item.id !== position.id);
        return prev;
      });
    } else {
      setSelectedPositions((prev) => {
        prev = [...prev, position];
        return prev;
      });
    }
  };

  const handleSelectAll = () => {
    if (selectedPositions.length < 1) {
      setSelectedPositions([...filteredData]);
    } else {
      setSelectedPositions([]);
    }
  };

  const handleReduce = (position: Position) => {
    if (isMobile) setShowDrawer(true);
    setReducePercent(50);

    if (position.id === reducePositionId) {
      setShowReduce(false);
      setReducePositionId(null);
      return;
    }

    setCurrentPosition(position);
    setReducePositionId(position.id);
    setShowReduce(false);
    setShowReduce(true);

    const button = reduceButtonRef.current[position.id];

    if (button) {
      const { top, left } = button.getBoundingClientRect();

      const x = left;
      const y = top;

      setReducePositions({ x, y });
    }
  };

  const handleOption = (key: string) => {
    if (key === 'checkbox') return;

    setTableColumns((prevTableItems) => {
      const updatedTableColumns = {
        ...prevTableItems,
        [key as keyof typeof tableColumns]: {
          ...prevTableItems[key as keyof typeof tableColumns],
          show: !prevTableItems[key as keyof typeof tableColumns].show,
        },
      };

      saveToLocalStorage('positionsTableColumns', updatedTableColumns);
      return updatedTableColumns;
    });
  };

  const closeSelectedPositions = async () => {
    const formattedPositionToClose: PositionToClosed[] = selectedPositions.map((position) => ({
      position_id: String(position.id),
      sub_account_id: position.sub_account_id,
      trading_type: position.instrument.instrument_type,
      exchange_id: position.exchange.id,
    }));

    if (!formattedPositionToClose.length) return;

    const { payload } = await dispatch(fetchPositionsClose(formattedPositionToClose));

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('positions_closed', 'success');
      setSelectedPositions([]);
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const closePosition = async (position: Position) => {
    const formattedPositionToClose: PositionToClosed = {
      position_id: String(position.id),
      sub_account_id: position.sub_account_id,
      trading_type: position.instrument.instrument_type,
      exchange_id: position.exchange.id,
    };

    setClosePositionButtonById((prev) => prev.concat(position.id));

    const { payload } = await dispatch(fetchPositionsClose([formattedPositionToClose]));

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('position_closed', 'success');
      setClosePositionButtonById((prev) => prev.filter((item) => item !== position.id));
      setSelectedPositions((prev) => prev.filter((item) => item.id !== position.id));
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
      setClosePositionButtonById((prev) => prev.filter((item) => item !== position.id));
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
      setClosePositionButtonById((prev) => prev.filter((item) => item !== position.id));
    } else {
      setAlertMessage('internal error', 'error');
      setClosePositionButtonById((prev) => prev.filter((item) => item !== position.id));
    }
  };

  const closeAllPositions = async () => {
    if (!positions.length) return;

    const { payload } = await dispatch(fetchPositionsCloseAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('positions_closed', 'success');
      unsubscribeInstrument();
      setSelectedPositions([]);
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const saveReduceSettings = async (position: Position) => {
    if (!reducePercent) {
      setAlertMessage('set_value_for_reduction', 'warning');
      setReduceInputFocus(true);
      return;
    }

    if (isMobile) { setShowDrawer(false); }

    const positionReduce: PositionReduceParams = {
      position_id: position.id,
      reduce_percent: reducePercent,
      sub_account_id: position.sub_account_id,
      trading_type: position.instrument.instrument_type,
      exchange_id: position.exchange.id,
    };

    const { payload } = await dispatch(fetchPositionReduce(positionReduce));

    if (payload === true) {
      setAlertMessage('position_reduced', 'success');
    } else if (typeof payload === 'string' && payload === 'REDUCED_QUANTITY_LESS_THAN_ALLOWED') {
      setAlertMessage('trade_volume_below_minimum', 'error');
      setShowReduce(false);
    } else if (typeof payload === 'string' && payload === 'Produced quantity is less than allowed on exchange') {
      setAlertMessage('trade_volume_below_exchange_limit', 'error');
      setShowReduce(false);
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
      setShowReduce(false);
    } else {
      setShowReduce(false);
      setAlertMessage('internal error', 'error');
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('positionsColumnOrder', newOrder);
  };

  const reduceCalculation = (quantity: string, reducePercent: number) => {
    const calculation = String(((parseFloat(quantity) / 100) * reducePercent));

    if (calculation.includes('.')) {
      const decimalPlaces = calculation.split('.')[1];

      if (decimalPlaces.length > 8) {
        return toFixedDecimal(toFixedDecimalPrecision(calculation, 8));
      }
    }

    return toFixedDecimal(calculation);
  };

  const visiblePositions = async () => {
    const formattedPositionToHidden: PositionToClosed[] = selectedPositions.map((position) => ({
      position_id: String(position.id),
      sub_account_id: position.sub_account_id,
      trading_type: position.instrument.instrument_type,
      exchange_id: position.exchange.id,
    }));

    const params: HiddenPositionsParams = {
      positions: formattedPositionToHidden,
      is_visible: true,
    };

    const { payload } = await dispatch(fetchHiddenPositions(params));

    if (Array.isArray(payload)) {
      dispatch(removePositionByIds(selectedPositions.map((item) => item.id)));
      setSelectedPositions([]);
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const hiddenPositions = async () => {
    const formattedPositionToHidden: PositionToClosed[] = selectedPositions.map((position) => ({
      position_id: String(position.id),
      sub_account_id: position.sub_account_id,
      trading_type: position.instrument.instrument_type,
      exchange_id: position.exchange.id,
    }));

    const params: HiddenPositionsParams = {
      positions: formattedPositionToHidden,
      is_visible: false,
    };

    const { payload } = await dispatch(fetchHiddenPositions(params));

    if (Array.isArray(payload)) {
      dispatch(removePositionByIds(selectedPositions.map((item) => item.id)));
      setSelectedPositions([]);
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const handleSelectPositionsType = (type: 'open' | 'close') => {
    if (type === positionsType) return;

    setPrevPositionsLength(0);
    setPositionsType(type);

    const fetchPositionType = type === 'open';

    setSelectedPositions([]);

    dispatch(fetchPositions(fetchPositionType));
  };

  const handleSetPositionCardType = (type: 'list' | 'card') => {
    saveToLocalStorage('positionCardType', type);
    setPositionCardType(type);
  };

  useLayoutEffect(() => {
    if (filterAccountType === ETradingType.spot) {
      setTableColumns((prev) => ({
        ...prev,
        leverage: {
          ...prev.leverage,
          show: false,
        },
      }));
    } else {
      const positionsTableColumnsLocalStorage = getFromLocalStorage('positionsTableColumns', null);

      if (positionsTableColumnsLocalStorage) {
        setTableColumns(positionsTableColumnsLocalStorage);
      }
    }
  }, [filterAccountType]);

  useLayoutEffect(() => {
    const positionsTableColumnsLocalStorage = getFromLocalStorage('positionsTableColumns', null);
    const positionsColumnLocalStorage = getFromLocalStorage('positionsColumnOrder', null);

    if (positionsColumnLocalStorage) {
      setColumnOrder(positionsColumnLocalStorage);
    }

    if (positionsTableColumnsLocalStorage) {
      setTableColumns(positionsTableColumnsLocalStorage);
    }
  }, []);

  useLayoutEffect(() => {
    if (isMobile) {
      setPositionCardType('card');
    }
  }, [isMobile]);

  useEffect(() => {
    if (filteredData.length !== previousPositionsLength) {
      if (filteredData.length > previousPositionsLength) {
        setShowReduce(false);
      } else {
        setShowReduce(false);
      }
      setPreviousPositionsLength(filteredData.length);
    }
  }, [filteredData, previousPositionsLength]);

  useEffect(() => {
    // Create a set of id's from filteredData for quick search
    const filteredIds = new Set(filteredData.map((item) => item.id));

    // Filter selectedPositions, leaving only those that are in filteredIds
    const updatedSelectedPositions = selectedPositions.filter((position) => filteredIds.has(position.id));

    // If the updated array differs from the current one, update the state
    if (updatedSelectedPositions.length !== selectedPositions.length) {
      setSelectedPositions(updatedSelectedPositions);
    }
  }, [selectedPositions, filteredData]);

  useEffect(() => {
    const showOptionsHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (!optionsRef.current?.contains(e.target as Node) && !optionsButtonRef.current?.contains(e.target as Node)) {
        setShowOptionsPositions(false);
      }
    };

    window.addEventListener('click', showOptionsHandler);

    return () => {
      window.removeEventListener('click', showOptionsHandler);
    };
  }, [showOptionsPositions]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const reduceButtonKeys = Object.keys(reduceButtonRef.current);

      const buttonClicked = reduceButtonKeys.some((key) => {
        const button = reduceButtonRef.current[key];
        return button instanceof Element && button.contains(e.target as Node);
      });

      if (buttonClicked) return;

      const clickedOutside = reduceTdRef.current && !reduceTdRef.current.contains(e.target as Node);

      if (clickedOutside) {
        setShowReduce(false);
        setReducePositionId(null);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [reduceButtonRef]);

  useEffect(() => {
    if (positions && positions.length > 0) {
      const instrumentIds: number[] = positions.map((position) => position.instrument.id);
      const uniqueIds = [...new Set(instrumentIds)];

      if (instrumentIds.length > 0 && positions.length !== prevPositionsLength) {
        subscribeInstrument(uniqueIds);

        setPrevPositionsLength(positions.length);
      }
    }
  }, [positions]);

  useEffect(() => {
    if (!currentPosition) return;

    const findPosition = positions.find((position) => position.id === currentPosition.id);

    if (showDrawer && findPosition) {
      setCurrentPosition(findPosition);
    }
  }, [positions, currentPosition, showDrawer]);

  useEffect(() => {
    const handleResize = () => {
      if (!reducePositionId) return;
      const button = reduceButtonRef.current[reducePositionId || 1];

      if (button) {
        const { top, left } = button.getBoundingClientRect();

        const x = left;
        const y = top;

        setReducePositions({ x, y });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [reducePositionId]);

  useEffect(() => {
    const divElement = document.querySelector('.content');

    const handleScrollVertical = () => {
      const button = reduceButtonRef.current[reducePositionId || 1];
      const positions = button ? button.getBoundingClientRect() : null;

      if (positions) {
        const x = positions.left;
        const y = positions.top;

        setReducePositions({ x, y });
      }
    };

    const handleScrollHorizontal = () => {
      const button = reduceButtonRef.current[reducePositionId || 1];
      if (!button) return;

      const { top, left } = button.getBoundingClientRect();

      const x = left;
      const y = top;

      setReducePositions({ x, y });
    };

    if (reducePositionId && divElement) {
      divElement.addEventListener('scroll', handleScrollHorizontal, { passive: true });
      window.addEventListener('scroll', handleScrollVertical);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScrollHorizontal);
      }
      window.removeEventListener('scroll', handleScrollVertical);
    };
  }, [reducePositionId]);

  useEffect(() => () => unsubscribeInstrument(), []);

  return (
    <section className={styles.openPositions}>
      <div className={styles.title}>
        <nav className={styles.navigation}>
          <ul className={styles.navList}>
            <li><button onClick={() => handleSelectPositionsType('open')} className={className(styles.navItem, { [styles.activeNav]: positionsType === 'open' })}>{t('open_positions')}</button></li>
            <li><span className={styles.navItemDivider}>|</span></li>
            <li><button onClick={() => handleSelectPositionsType('close')} className={className(styles.navItem, { [styles.activeNav]: positionsType === 'close' })}>{t('hidden_positions')}</button></li>
          </ul>
        </nav>
        <div className={styles.actionsOptions}>
          <button ref={optionsButtonRef} onClick={() => setShowOptionsPositions((prev) => !prev)}>
            <ThreePointsIconSvg />
          </button>
        </div>

        { showOptionsPositions && (
          <div ref={optionsRef} className={styles.options}>
            <p className={styles.optionsTitle}>{t('table-settings')}</p>
            <ul>
              {Object.entries(tableColumns).map(([key, value]) => (
                <li
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleOption(key)}
                  onClick={() => handleOption(key)}
                  key={key}
                >
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={value.show}
                    onChange={() => {}}
                  />
                  <span>{t(value.name)}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <button className={className({ [styles.activeFilter]: filterAccountType === '' })} onClick={() => setFilterAccountType('')}>{t('all')}</button>
            <button className={className({ [styles.activeFilter]: filterAccountType === ETradingType.futures })} onClick={() => setFilterAccountType(ETradingType.futures)}>{t('futures')}<span>F</span> </button>
            <button className={className({ [styles.activeFilter]: filterAccountType === ETradingType.spot })} onClick={() => setFilterAccountType(ETradingType.spot)}>{t('spot')}<span>S</span> </button>
          </div>
          <div className={styles.actions}>
            {selectedPositions.length > 0 && <Button minWidth="100px" loading={hiddenStatus === EStatus.loading} disabled={hiddenStatus === EStatus.loading} onClick={positionsType === 'close' ? visiblePositions : hiddenPositions} background="gray">{positionsType === 'close' ? t('show') : t('hide')}</Button>}

            <div className={styles.windows}>
              <Tooltip withArrow label={t('table')} position="bottom" offset={5}>
                <button className={className({ [styles.active]: positionCardType === 'list' })} onClick={() => handleSetPositionCardType('list')}><ListCionSvg /></button>
              </Tooltip>

              <Tooltip withArrow label={t('cards')} position="bottom" offset={5}>
                <button className={className({ [styles.active]: positionCardType === 'card' })} onClick={() => handleSetPositionCardType('card')}><GridIconSvg /></button>
              </Tooltip>
            </div>

            <div className={styles.tableActions}>
              {selectedPositions.length > 0 && <Button minWidth="175px" loading={closePositionStatus === EStatus.loading} disabled={closePositionStatus === EStatus.loading} onClick={closeSelectedPositions} background="orange">{t('close_selected')} ({selectedPositions.length})</Button>}
              {positionsType === 'open' && positions && positions.length > 0 && <Button minWidth="140px" loading={closePositionsStatus === EStatus.loading} disabled={closePositionsStatus === EStatus.loading} onClick={closeAllPositions} background="green">{t('close_all')}</Button>}
            </div>

          </div>
        </div>

        {positionCardType === 'list' && (
          <div className={styles.bodyList}>
            <DragDropContext onDragEnd={onDragEnd}>
              <table className={styles.tablePositions}>
                <thead>
                  <Droppable droppableId="columns" direction="horizontal">
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                        }}
                      >
                        {columnOrder.map((column, index) => (
                          <Draggable key={column.key} draggableId={column.key} index={index}>
                            {(provided, snapshot) => (
                              <th
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                                  color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                                  display: snapshot.isDragging ? 'flex' : '',
                                  width: column.key === 'checkbox' ? '50px' : 'auto',
                                }}
                              >
                                {tableColumns.checkbox.show && column.key === 'checkbox' && (
                                  <input
                                    className={styles.checkbox}
                                    type="checkbox"
                                    checked={selectedPositions.length === filteredData.length && filteredData.length > 0}
                                    onChange={handleSelectAll}
                                  />
                                )}
                                {tableColumns.instrument.show && column.key === 'instrument_symbol' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.exchange.show && column.key === 'exchange_name' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.leverage.show && column.key === 'leverage' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.subAccountId.show && column.key === 'sub_account_id' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.side.show && column.key === 'side' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.quantity.show && column.key === 'quantity' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.averagePrice.show && column.key === 'average_price' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.price.show && column.key === 'price' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.pnlInPercents.show && column.key === 'pnlInPercents' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.pnlInCurrency.show && column.key === 'pnlInCurrency' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.reduce.show && column.key === 'reduce' && (
                                  <div className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}>
                                    {t(column.label)}
                                    {/* <TipIconSvg /> */}
                                  </div>
                                )}
                                {tableColumns.close.show && column.key === 'close' && (
                                  <div className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}>
                                    {t(column.label)}
                                    {/* <TipIconSvg /> */}
                                  </div>
                                )}

                              </th>
                            )}
                          </Draggable>
                        ))}
                        { provided.placeholder}
                      </tr>
                    )}
                  </Droppable>
                </thead>

                {positions && positions.length > 0 && (
                  <tbody>
                    {filteredData?.map((position) => (
                      <tr key={position.id}>
                        {columnOrder.map((column) => (
                          <td key={column.key}>
                            {column.key === 'checkbox' && tableColumns.checkbox.show && (
                              <input
                                className={styles.checkbox}
                                type="checkbox"
                                checked={Boolean(selectedPositions.find((item) => item.id === position.id))}
                                onChange={() => handleSelectPosition(position)}
                              />
                            )}
                            {column.key === 'instrument_symbol' && tableColumns.instrument.show && (
                              <div
                                role="button"
                                tabIndex={0}
                                onClick={() => handleSelectPosition(position)}
                                onKeyDown={() => handleSelectPosition(position)}
                                className={styles.instrumentTd}
                              >
                                {position.instrument.symbol}
                                <BalanceType type={position.instrument.instrument_type} />
                              </div>
                            ) }
                            {column.key === 'exchange_name' && tableColumns.exchange.show && (
                              <div className={styles.exchangeTd}>
                                <img src={position.exchange.image} alt="exchange" />
                              </div>
                            ) }
                            {column.key === 'leverage' && tableColumns.leverage.show && (
                              <div>{position.leverage ? `x${position.leverage}` : '-'}</div>
                            ) }
                            {column.key === 'sub_account_id' && tableColumns.subAccountId.show && (
                              <div className={styles.accountTd}>{getSubAccountNameById(position.sub_account_id)}</div>
                            ) }
                            {column.key === 'side' && tableColumns.side.show && (
                              <div className={className(styles.directionTd, { [styles.short]: position.side === 'SHORT' })}>{position.side[0]}</div>
                            ) }
                            {column.key === 'quantity' && tableColumns.quantity.show && (
                              <div className={styles.quantityTd}>{!balanceVisible ? toFixedDecimal(position.quantity) : HIDDEN_BALANCES}</div>
                            ) }
                            {column.key === 'quantity_dollars' && tableColumns.quantity_dollars.show && (
                              <div className={styles.quantityTd}>{!balanceVisible ? multiplyValues(position.average_price, position.quantity, 2) : HIDDEN_BALANCES}</div>
                            ) }
                            {column.key === 'average_price' && tableColumns.averagePrice.show && (
                              <div className={styles.averagePriceTd}>{toFixedDecimal(position.average_price)}</div>
                            ) }
                            {column.key === 'price' && tableColumns.price.show && (
                              <div className={styles.lastPriceTd}>{toFixedDecimalPrecision(position.side === 'LONG' ? position.instrument.price.bid : position.instrument.price.ask)}</div>
                            ) }
                            {column.key === 'pnlInPercents' && tableColumns.pnlInPercents.show && (
                              <div className={className(
                                styles.pnlTd,
                                { [styles.minus]: Number(calculatePnlInPercents(position)) < 0 },
                                { [styles.plus]: Number(calculatePnlInPercents(position)) > 0 },

                              )}
                              >{calculatePnlInPercents(position)} %
                              </div>
                            ) }
                            {column.key === 'pnlInCurrency' && tableColumns.pnlInCurrency.show && (
                              <div className={className(
                                styles.pnlTd,
                                { [styles.minus]: Number(calculatePnl(position)) < 0 },
                                { [styles.plus]: Number(calculatePnl(position)) > 0 },

                              )}
                              >{
                                  !balanceVisible ? `${calculatePnl(position)} $` : HIDDEN_BALANCES
                                }
                              </div>
                            ) }
                            {column.key === 'reduce' && tableColumns.reduce.show && (
                              <div className={styles.reduceTd}>
                                <button
                                  ref={(element) => {
                                    if (element && position.id) {
                                      reduceButtonRef.current![position.id] = element;
                                    }
                                  }}
                                  onClick={() => handleReduce(position)}
                                  className={className(styles.firstActionTd, { [styles.activeReduce]: reducePositionId === position.id })}
                                >
                                  {t('reduce')}
                                  <ReduceIconSvg />
                                </button>
                                {showReduce && reducePositionId === position.id && (
                                  <div ref={reduceTdRef} style={{ top: `${reducePositions.y + 30}px`, left: `${reducePositions.x - 75}px` }} className={styles.reduce}>
                                    <div className={styles.pos} />
                                    <div className={styles.reduceBody}>
                                      <img src={position.exchange.image} alt="exchange" />
                                      <span>{position.instrument.symbol}</span>
                                      <BalanceType type={position.instrument.instrument_type} />
                                    </div>

                                    <div className={styles.calculateBody}>
                                      <div className={styles.inputText}>
                                        <InputText
                                          inputType="number"
                                          value={reducePercent}
                                          onChange={handleChangeReducePercent}
                                        />
                                      </div>

                                      <div className={styles.decrementBtn}>
                                        <Button onClick={incrementCount} background="orange">+</Button>
                                      </div>

                                      <div className={styles.incrementBtn}>
                                        <Button onClick={decrementCount} background="orange">-</Button>
                                      </div>

                                      <div className={styles.rangeslider}>
                                        <ReduceSlider value={reducePercent} onChange={handleOnChangeReduceValue} />
                                      </div>

                                      <div className={styles.tab}>
                                        <button className={className({ [styles.activeTab]: reducePercent === 25 })} onClick={() => setReducePercent(25)}>25%</button>
                                        <button className={className({ [styles.activeTab]: reducePercent === 50 })} onClick={() => setReducePercent(50)}>50%</button>
                                        <button className={className({ [styles.activeTab]: reducePercent === 75 })} onClick={() => setReducePercent(75)}>75%</button>
                                        <button className={className({ [styles.activeTab]: reducePercent === 100 })} onClick={() => setReducePercent(100)}>100%</button>
                                      </div>

                                      <Button loading={statusReducePosition === EStatus.loading} disabled={statusReducePosition === EStatus.loading} onClick={() => saveReduceSettings(position)} background="green">{t('reduce')}</Button>

                                      <span className={styles.infoText}>
                                        {t('amount')}: {reduceCalculation(position.quantity, reducePercent)} {position.instrument.symbol}
                                        <br />
                                        (~ {toFixedDecimalPrecision(String(((parseFloat(position.quantity) / 100) * reducePercent) * parseFloat(position.side === 'LONG' ? position.instrument.price.bid : position.instrument.price.ask)), 2)} USDT)
                                      </span>

                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {column.key === 'close' && tableColumns.close.show && (
                              <button disabled={closePositionButtonById.includes(position.id)} onClick={() => closePosition(position)} className={styles.secondActionTd}>{t('close')} <CloseIconSvg /></button>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </DragDropContext>

            {(positionsStatus === EStatus.success && positions && filteredData.length === 0) && (
              <div className={styles.emptyTable}>
                <Empty className={styles.emptyText}>
                  {t('your_positions_displayed_here')}
                </Empty>
              </div>
            )}
            {positionsStatus === EStatus.loading && (
              <div className={styles.emptyTable}>
                <LoadingOverlay
                  className="loader"
                  style={{ height: 'calc(100% - 100px)' }}
                  visible
                  zIndex={1000}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                  loaderProps={{ color: '#00C37C', type: 'dots' }}
                />
              </div>
            )}
            {positionsStatus === EStatus.rejected && (
              <div className={styles.emptyTable}>
                <Empty className={styles.emptyTextError} horizontal error>
                  {t('internal error fetch')}
                </Empty>
              </div>
            )}
          </div>
        )}
        {positionCardType === 'card' && (
          <div className={styles.bodyCard}>

            {filteredData && filteredData.length > 0 && (
              <div className={styles.positionCardContainer}>
                {filteredData?.map((position) => (
                  <div className={className(styles.positionCardWrapper, { [styles.active]: Boolean(selectedPositions.find((item) => item.id === position.id)) })} key={position.id}>
                    <div
                      className={styles.positionCard}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSelectPosition(position)}
                      onKeyDown={() => {}}
                    >
                      {(tableColumns.exchange.show || tableColumns.instrument.show || tableColumns.reduce.show || tableColumns.close.show) ? (
                        <div className={styles.box}>
                          {tableColumns.exchange.show && (
                            <div className={styles.exchange}>
                              <img src={position.exchange.image} alt="exchange" />
                            </div>
                          ) }
                          {tableColumns.instrument.show && (
                            <div className={styles.instrument}>
                              {position.instrument.symbol}
                              <BalanceType type={position.instrument.instrument_type} />
                            </div>
                          ) }

                          {!isMobile && tableColumns.reduce.show && (
                            <div className={styles.reduceTd}>
                              <Tooltip withArrow label={t('reduce')} position="bottom" offset={5}>
                                <button
                                  ref={(element) => {
                                    if (element && position.id) {
                                       reduceButtonRef.current![position.id] = element;
                                    }
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleReduce(position);
                                  }}
                                  className={className(styles.reduceActionTd, { [styles.activeReduce]: reducePositionId === position.id })}
                                >
                                  <ReduceIconSvg />
                                </button>
                              </Tooltip>

                              {showReduce && reducePositionId === position.id && (
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onClick={(e) => e.stopPropagation()}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  ref={reduceTdRef}
                                  style={{ top: `${reducePositions.y + 30}px`, left: `${reducePositions.x - 100}px` }}
                                  className={styles.reduce}
                                >
                                  <div className={styles.pos} />
                                  <div className={styles.reduceBody}>
                                    <img src={position.exchange.image} alt="exchange" />
                                    <span>{position.instrument.symbol}</span>
                                    <BalanceType type={position.instrument.instrument_type} />
                                  </div>

                                  <div className={styles.calculateBody}>
                                    <div className={styles.inputText}>
                                      <InputText
                                        focus={reduceInputFocus}
                                        value={reducePercent}
                                        onChange={handleChangeReducePercent}
                                      />
                                    </div>

                                    <div className={styles.decrementBtn}>
                                      <Button onClick={incrementCount} background="orange">+</Button>
                                    </div>

                                    <div className={styles.incrementBtn}>
                                      <Button onClick={decrementCount} background="orange">-</Button>
                                    </div>

                                    <div className={styles.rangeslider}>
                                      <ReduceSlider value={reducePercent} onChange={handleOnChangeReduceValue} />
                                    </div>

                                    <div className={styles.tab}>
                                      <button className={className({ [styles.activeTab]: reducePercent === 25 })} onClick={() => setReducePercent(25)}>25%</button>
                                      <button className={className({ [styles.activeTab]: reducePercent === 50 })} onClick={() => setReducePercent(50)}>50%</button>
                                      <button className={className({ [styles.activeTab]: reducePercent === 75 })} onClick={() => setReducePercent(75)}>75%</button>
                                      <button className={className({ [styles.activeTab]: reducePercent === 100 })} onClick={() => setReducePercent(100)}>100%</button>
                                    </div>

                                    <Button loading={statusReducePosition === EStatus.loading} disabled={statusReducePosition === EStatus.loading} onClick={() => saveReduceSettings(position)} background="green">{t('reduce')}</Button>

                                    <span className={styles.infoText}>
                                      {t('amount')}: {reduceCalculation(position.quantity, reducePercent)} {position.instrument.symbol}
                                      <br />
                                      (~ {toFixedDecimalPrecision(String(((parseFloat(position.quantity) / 100) * reducePercent) * parseFloat(position.side === 'LONG' ? position.instrument.price.bid : position.instrument.price.ask)), 2)} USDT)
                                    </span>

                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          {!isMobile && tableColumns.close.show && (
                            <Tooltip withArrow label={t('close_position')} position="bottom" offset={5}>
                              <button
                                disabled={closePositionButtonById.includes(position.id)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  closePosition(position);
                                }}
                                className={styles.closeActionTd}
                              >
                                <CloseXIconSvg />
                              </button>
                            </Tooltip>

                          )}
                        </div>
                      ) : null}

                      {(tableColumns.subAccountId.show || tableColumns.leverage.show) ? (
                        <div className={styles.box}>
                          {tableColumns.subAccountId.show && (
                            <div className={styles.subAccount}>{`${t('account')}: ${getSubAccountNameById(position.sub_account_id)}`}</div>
                          ) }
                          {tableColumns.leverage.show && position.leverage && (
                            <div className={styles.leverage}>x{position.leverage}</div>
                          )}
                        </div>
                      ) : null}

                      { (tableColumns.pnlInPercents.show || tableColumns.side.show || tableColumns.pnlInCurrency.show) ? (
                        <div className={styles.box}>
                          {tableColumns.pnlInPercents.show && (
                            <div className={className(
                              styles.pnl,
                              { [styles.minus]: Number(calculatePnlInPercents(position)) < 0 },
                              { [styles.plus]: Number(calculatePnlInPercents(position)) > 0 },
                            )}
                            >
                              {calculatePnlInPercents(position)} %
                            </div>
                          )}

                          {tableColumns.side.show && (
                            <div className={className(styles.side, { [styles.short]: position.side === 'SHORT' })}>{position.side}</div>
                          )}

                          {tableColumns.pnlInCurrency.show && (
                            <div className={className(
                              styles.pnl,
                              { [styles.minus]: Number(calculatePnl(position)) < 0 },
                              { [styles.plus]: Number(calculatePnl(position)) > 0 },
                            )}
                            >
                              {!balanceVisible ? `${calculatePnl(position)} $` : HIDDEN_BALANCES}
                            </div>
                          )}
                        </div>
                      ) : null}

                      {(tableColumns.averagePrice.show || tableColumns.price.show) ? (
                        <div className={styles.box}>
                          {tableColumns.averagePrice.show && (
                            <div className={styles.averagePrice}>{toFixedDecimal(position.average_price)}$ <CircleArrowRightIconSvg /></div>
                          )}
                          {tableColumns.price.show && (
                            <div className={styles.lastPrice}>{toFixedDecimalPrecision(position.side === 'LONG' ? position.instrument.price.bid : position.instrument.price.ask)}$</div>
                          )}
                        </div>
                      ) : null}

                      {(tableColumns.quantity_dollars.show || tableColumns.quantity.show) ? (
                        <div className={styles.box}>
                          {tableColumns.quantity.show && (
                            <div className={styles.quantity}>
                              {!balanceVisible ? toFixedDecimal(position.quantity) : HIDDEN_BALANCES}
                              <span className={styles.symbol}>{position.instrument.symbol}</span>
                            </div>
                          )}
                          {tableColumns.quantity_dollars.show && (
                            <div className={className(
                              styles.quantityDollars,
                              { [styles.minus]: Number(multiplyValues(position.average_price, String(position.quantity))) < 0 },
                              { [styles.plus]: Number(multiplyValues(position.average_price, String(position.quantity))) > 0 },
                            )}
                            >
                              {!balanceVisible ? `${multiplyValues(position.average_price, String(position.quantity), 2)}$` : HIDDEN_BALANCES}
                            </div>
                          )}
                        </div>
                      ) : null}

                      {isMobile && (tableColumns.reduce.show || tableColumns.close.show) ? (
                        <div className={styles.boxMobile}>
                          {tableColumns.reduce.show && (
                            <div
                              className={styles.reduceTdMobile}
                              role="button"
                              tabIndex={0}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleReduce(position);
                              }}
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              <button
                                ref={(element) => {
                                  if (element && position.id) {
                                       reduceButtonRef.current![position.id] = element;
                                  }
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleReduce(position);
                                }}
                                className={className(styles.reduceActionTds, { [styles.activeReduce]: reducePositionId === position.id })}
                              >
                                <ReduceIconSvg />
                                {t('reduce')}
                              </button>
                            </div>
                          )}
                          {tableColumns.close.show && (
                            <Button
                              loading={closePositionButtonById.includes(position.id)}
                              disabled={closePositionButtonById.includes(position.id)}
                              background="black"
                              onClick={(e) => {
                                e.stopPropagation();
                                closePosition(position);
                              }}
                            >
                              <CloseXIconSvg /> {t('close')}
                            </Button>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {(positionsStatus === EStatus.success && positions && filteredData.length === 0) && (
              <div className={styles.emptyTable}>
                <Empty className={styles.emptyText}>
                  {t('your_positions_displayed_here')}
                </Empty>
              </div>
            )}
            {positionsStatus === EStatus.loading && (
              <div className={styles.emptyTable}>
                <LoadingOverlay
                  className="loader"
                  style={{ height: 'calc(100% - 100px)' }}
                  visible
                  zIndex={1000}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                  loaderProps={{ color: '#00C37C', type: 'dots' }}
                />
              </div>
            )}
            {positionsStatus === EStatus.rejected && (
              <div className={styles.emptyTable}>
                <Empty className={styles.emptyTextError} horizontal error>
                  {t('internal error fetch')}
                </Empty>
              </div>
            )}
          </div>
        )}
      </div>

      <Drawer className={styles.drawer} onClose={() => setShowDrawer(false)} anchor="bottom" open={showDrawer}>
        {currentPosition && (
          <>
            <div className={styles.reduceHeader}>
              <h1>{t('position_reduc')}</h1>
              <ButtonClose darkTheme={theme === EThemeMode.LIGHT} onClick={() => setShowDrawer(false)} />
            </div>

            <div className={styles.reduceModile}>
              <div className={styles.pos} />
              <div className={styles.reduceBody}>
                <img src={currentPosition.exchange.image} alt="exchange" />
                <span>{currentPosition.instrument.symbol}</span>
                <BalanceType type={currentPosition.instrument.instrument_type} />
                <span className={styles.subAccount}>{t('account')}: {getSubAccountNameById(currentPosition.sub_account_id)}</span>
              </div>

              <div className={styles.box}>
                <div className={className(
                  styles.pnl,
                  { [styles.minus]: Number(calculatePnlInPercents(currentPosition)) < 0 },
                  { [styles.plus]: Number(calculatePnlInPercents(currentPosition)) > 0 },
                )}
                >
                  {calculatePnlInPercents(currentPosition)} %
                </div>
                <div className={className(styles.side, { [styles.short]: currentPosition.side === 'SHORT' })}>{currentPosition.side}</div>
                <div className={className(
                  styles.pnl,
                  { [styles.minus]: Number(calculatePnl(currentPosition)) < 0 },
                  { [styles.plus]: Number(calculatePnl(currentPosition)) > 0 },
                )}
                >
                  {calculatePnl(currentPosition)} $
                </div>
              </div>

              <div className={styles.box}>
                <div className={styles.averagePrice}>{toFixedDecimal(currentPosition.average_price)}USDT <CircleArrowRightIconSvg /></div>
                <div className={styles.lastPrice}>{toFixedDecimalPrecision(currentPosition.side === 'LONG' ? currentPosition.instrument.price.bid : currentPosition.instrument.price.ask)} USDT</div>
              </div>

              <div className={styles.box}>
                <div className={styles.quantity}>
                  {!balanceVisible ? toFixedDecimal(currentPosition.quantity) : HIDDEN_BALANCES}
                  <span className={styles.symbol}>{currentPosition.instrument.symbol}</span>
                </div>

                <div className={className(
                  styles.quantityDollars,
                  { [styles.minus]: Number(multiplyValues(currentPosition.average_price, currentPosition.quantity)) < 0 },
                  { [styles.plus]: Number(multiplyValues(currentPosition.average_price, currentPosition.quantity)) > 0 },
                )}
                >
                  {!balanceVisible ? `${multiplyValues(currentPosition.average_price, currentPosition.quantity, 2)}$` : HIDDEN_BALANCES}
                </div>

              </div>

              <div className={styles.calculateBody}>
                <div className={styles.inputText}>
                  <InputText
                    focus={reduceInputFocus}
                    value={reducePercent}
                    onChange={handleChangeReducePercent}
                  />
                </div>

                <div className={styles.decrementBtn}>
                  <Button onClick={incrementCount} background="orange">+</Button>
                </div>

                <div className={styles.incrementBtn}>
                  <Button onClick={decrementCount} background="orange">-</Button>
                </div>

                <div className={styles.rangeslider}>
                  <ReduceSlider value={reducePercent} onChange={handleOnChangeReduceValue} />
                </div>

                <div className={styles.tab}>
                  <button className={className({ [styles.activeTab]: reducePercent === 25 })} onClick={() => setReducePercent(25)}>25%</button>
                  <button className={className({ [styles.activeTab]: reducePercent === 50 })} onClick={() => setReducePercent(50)}>50%</button>
                  <button className={className({ [styles.activeTab]: reducePercent === 75 })} onClick={() => setReducePercent(75)}>75%</button>
                  <button className={className({ [styles.activeTab]: reducePercent === 100 })} onClick={() => setReducePercent(100)}>100%</button>
                </div>

                <Button onClick={() => saveReduceSettings(currentPosition)} background="green">{t('save')}</Button>

                <span className={styles.infoText}>
                  {t('amount')}: {reduceCalculation(currentPosition.quantity, reducePercent)} {currentPosition.instrument.symbol}

                  (~ {toFixedDecimalPrecision(String(((parseFloat(currentPosition.quantity) / 100) * reducePercent) * parseFloat(currentPosition.side === 'LONG' ? currentPosition.instrument.price.bid : currentPosition.instrument.price.ask)), 2)} USDT)
                </span>

              </div>
            </div>
          </>
        )}
      </Drawer>
    </section>
  );
};

export default OpenPositions;
