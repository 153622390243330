import { InstrumentPrice } from 'src/app/store/slices/assets/types';

export enum EStatus {
  loading = 'loading',
  success = 'success',
  rejected = 'rejected',
}
export enum ETradingType {
  spot = 1,
  futures = 2,
  unified = 3,
}

export enum EEnvironment {
  development = 'development',
  production = 'production',
}

export type Tokens = {
  access_token: string
  refresh_token: string
}

export type Meta = {
  total_items: number
  total_pages: number
}

export type ColumnOrderItem = {
  key: string
  label: string
};

export type TableColumnItem = {
  key: string
  visible: boolean
};

export type LoginVerificationType = {
  name: string
  type: string
}

export type UpdateInstrument = {
  id: number
  price: InstrumentPrice
}

export type SendTypeCode = {
  type: 'phone' | 'whatsapp'
  name: string
}

export type ModalSteps = {
  step: number
  setStep: (value: number) => void
}

export type SubAccountsModify = {
  id: number
  name: string
  type: TradingType
  is_favorite: boolean
  exchange_id: number
}

export type Nullable<T> = undefined | null | T;

export type DetailError = { detail: string }

export type Language = {
  type: string
  name: string
}

export type TradingType = 'SPOT' | 'FUTURES' | 'UNIFIED'

export type TradingTypeProps = {
  type: TradingType
  name: string
}

export type DateRange = 'Today' | 'Yesterday' | 'Week' | 'Month' | 'Year'

export enum EDateRange {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year'
}

export type DateRangeType = {
  range: DateRange
}
