import { Nullable, Tokens } from 'src/shared/types/global-types';

export const saveTokensLocalStorage = (data: Tokens): void => {
  try {
    if (data !== null && data !== undefined) {
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);
    }
  } catch (error) {
    console.debug('Failed save tokens', error);
  }
};

export const getTokensFromLocalStorage = (): Nullable<Tokens> => {
  const encryptedAccessToken = localStorage.getItem('accessToken');
  const encryptedRefreshToken = localStorage.getItem('refreshToken');

  if (encryptedAccessToken && encryptedRefreshToken) {
    return {
      access_token: encryptedAccessToken,
      refresh_token: encryptedRefreshToken,
    };
  }

  return null;
};
