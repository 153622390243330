import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/shared/ui/button/button/button';
import HamsterErrorIconSvg from 'src/shared/assets/images/hamster-error.svg';
import HamsterErrorHoverIconSvg from 'src/shared/assets/images/hamster-error-hover.svg';
import styles from './disconnected-ws-modal-content.module.scss';

const DisconnectedWsModalContent = () => {
  const { t } = useTranslation();
  const [switchHamster, setSwitchHamster] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSwitchHamster(true);
      const timeout = setTimeout(() => {
        setSwitchHamster(false);
      }, 500);

      // Очистка timeout в случае обновления интервала
      return () => clearTimeout(timeout);
    }, 2000);

    // Очистка интервала при размонтировании компонента
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.content}>
      {!switchHamster ? <img src={HamsterErrorIconSvg} alt="hamster" /> : <img src={HamsterErrorHoverIconSvg} alt="hamster" />}
      <h3>{t('connection')} <br /> {t('was_interrupted')}</h3>
      <p>{t('please_refresh_page')}, <br /> {t('restore_connection')}</p>
      <Button onClick={() => window.location.reload()} maxWidth="200px" background="green">{t('refresh_page')}</Button>
    </div>
  );
};

export default DisconnectedWsModalContent;
