import moment from 'moment';
import className from 'classnames';
import {
  memo, useEffect, useRef, useState, useLayoutEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, LoadingOverlay } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useAppDispatch } from 'src/app/store/store';

import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as ExcelBtnIconSvg } from 'src/shared/assets/images/excel-btn.svg';
import { ReactComponent as ThreePointsIconSvg } from 'src/shared/assets/images/trading/three-points.svg';

import Empty from 'src/entities/empty/empty';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';

import { EStatus, Nullable } from 'src/shared/types/global-types';
import { BalanceType } from 'src/entities/balance-type';
import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import {
  getDate,
  getFromLocalStorage, multiplyValues, saveToLocalStorage, toFixedDecimal,
  toFixedDecimalPrecision,
} from 'src/shared/libs/helpers/helper.lib';
import { sortPositionsSummury } from 'src/pages/account/libs/helpers';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import { transactionHistoryColumn, transactionHistoryColumns } from './constants';
import { selectAccount } from '../../../../model/selectors';
import { clearPositionsSummury } from '../../../../model/slice';
import { SortFieldPositionsSummary } from '../../../../model/types';
import {
  fetchPositionsSummury, PositionsSummuryParams, subscribePositionsSummury, unsubscribePositionsSummury,
} from '../../../../model/thunks';
import styles from './positions-summury-table.module.scss';

  interface ITransactionHistoryProps {
    subAccountId?: Nullable<number>
  }

const PositionsSummuryTable = memo(({ subAccountId }: ITransactionHistoryProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { theme } = useTheme();

  const balanceVisible = useSelector(selectHiddenBalance);
  const { positionsSummury, status } = useSelector(selectAccount);

  const [fetchPage, setFetchPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortField, setSortField] = useState<Nullable<SortFieldPositionsSummary>>(null);
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(transactionHistoryColumn);
  const [showOptionsTransactions, setShowOptionsTransactions] = useState(false);
  const [tableColumns, setTableColumns] = useState(transactionHistoryColumns);

  const optionsRef = useRef<HTMLDivElement>(null);
  const optionsButtonRef = useRef<HTMLButtonElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { currentDateAsString, sevenDaysAgoAsString } = getDate();
  const [dateRange, setDateRange] = useState<any>([sevenDaysAgoAsString, currentDateAsString]);
  const [startDate, endDate] = dateRange;

  const sortedPositionsSummury = useMemo(() => {
    if (!positionsSummury) return [];
    if (!sortField) return positionsSummury.items;

    const filterBySubAccount = positionsSummury.items.filter((item) => item.sub_account_id === subAccountId);

    return filterBySubAccount.slice().sort((a, b) => sortPositionsSummury(a, b, sortField, sortOrder));
  }, [sortField, sortOrder, positionsSummury, subAccountId]);

  useIntersectionObserver(fetchPage, positionsSummury?.meta.total_pages || 1, sortedPositionsSummury, 'history-item', setFetchPage, 9);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('transactionHistoryColumnOrder', newOrder);
  };

  const handleSort = (field: SortFieldPositionsSummary) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleClearPositionsSummury = () => {
    dispatch(clearPositionsSummury());
    unsubscribePositionsSummury();

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo(0, 0);
    }
  };

  const handleResetFilter = () => {
    if (!subAccountId) return;

    const currentDate = moment();
    const startDateCurrent = currentDate.clone().subtract(7, 'days');

    const start = startDateCurrent.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const checkStartDate = moment(startDate).format('YYYY-MM-DD');
    const checkEndDate = moment(endDate).format('YYYY-MM-DD');

    if (checkStartDate === moment(start).format('YYYY-MM-DD') && checkEndDate === moment(end).format('YYYY-MM-DD')) return;

    handleClearPositionsSummury();

    setFetchPage(1);
    setDateRange([start, end]);
  };

  const handleOption = (key: string) => {
    setTableColumns((prevTableItems) => {
      const updatedTableColumns = {
        ...prevTableItems,
        [key as keyof typeof tableColumns]: {
          ...prevTableItems[key as keyof typeof tableColumns],
          show: !prevTableItems[key as keyof typeof tableColumns].show,
        },
      };

      saveToLocalStorage('transactionHistoryTableColumns', updatedTableColumns);
      return updatedTableColumns;
    });
  };

  useLayoutEffect(() => {
    const transactionHistoryTableColumnsLocalStorage = getFromLocalStorage('transactionHistoryTableColumns', null);
    const transactionHistoryColumnLocalStorage = getFromLocalStorage('transactionHistoryColumnOrder', null);

    if (transactionHistoryTableColumnsLocalStorage) {
      setTableColumns(transactionHistoryTableColumnsLocalStorage);
    }
    if (transactionHistoryColumnLocalStorage) {
      setColumnOrder(transactionHistoryColumnLocalStorage);
    }

    return () => {
      handleClearPositionsSummury();
    };
  }, []);

  useEffect(() => {
    if (!subAccountId) return;

    const fetchParams: PositionsSummuryParams = {
      query: {
        page: fetchPage,
        limit: 10,
      },
      body: {
        from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        sub_account_id: subAccountId,
      },
    };

    if (startDate && endDate) {
      setSortField(null);
      setCurrentSortColumn('');
      dispatch(fetchPositionsSummury(fetchParams));
    }
  }, [startDate, endDate, fetchPage, subAccountId]);

  useEffect(() => {
    subscribePositionsSummury();
  }, [endDate]);

  /* user events */
  useEffect(() => {
    const showOptionsHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (!optionsRef.current?.contains(e.target as Node) && !optionsButtonRef.current?.contains(e.target as Node)) {
        setShowOptionsTransactions(false);
      }
    };

    window.addEventListener('click', showOptionsHandler);

    return () => {
      window.removeEventListener('click', showOptionsHandler);
    };
  }, [showOptionsTransactions]);

  return (
    <section className={styles.transactionHistory}>
      <div className={styles.title}>
        <h2>{t('trade_history')}</h2>
        <div className={styles.actionsOptions}>
          <button ref={optionsButtonRef} onClick={() => setShowOptionsTransactions((prev) => !prev)}>
            <ThreePointsIconSvg />
          </button>
        </div>

        { showOptionsTransactions && (
          <div ref={optionsRef} className={styles.options}>
            <p className={styles.optionsTitle}>{t('table-settings')}</p>
            <ul>
              {Object.entries(tableColumns).map(([key, value]) => (
                <li
                  key={key}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleOption(key)}
                  onClick={() => handleOption(key)}
                >
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={value.show}
                    onChange={() => {}}
                  />
                  <span>{t(value.name)}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <header className={styles.header}>
        <DataPickerRange whiteMode={theme === EThemeMode.LIGHT} removeData={handleClearPositionsSummury} setPage={setFetchPage} setDateRange={setDateRange} dateRange={dateRange} />

        <div className={styles.buttons}>
          {/* <Tooltip withArrow label="Скачать историю" position="left" offset={9}>
            <ExcelBtnIconSvg />
          </Tooltip> */}
          <Button onClick={handleResetFilter} maxWidth="" minWidth="150px" background={theme === EThemeMode.DARK ? 'white' : 'black'}>{t('reset filters')}</Button>
        </div>
      </header>

      <div ref={tableContainerRef} className={styles.historyTableWrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <table className={styles.table}>
            <thead>
              <Droppable droppableId="columns" direction="horizontal">
                {(provided, snapshot) => (
                  <tr
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                    }}
                    className={snapshot.isDraggingOver ? styles.dragging : styles.notDragging}

                  >
                    {columnOrder.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided, snapshot) => (
                          <th
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={snapshot.isDragging ? styles.dragging : styles.notDragging}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                              color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                              display: snapshot.isDragging ? 'flex' : '',
                              width: column.key === 'checkbox' ? '50px' : 'auto',
                            }}
                          >
                            {tableColumns.entered_at.show && column.key === 'entered_at' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.instrument.show && column.key === 'instrument' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.side.show && column.key === 'side' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.pnl.show && column.key === 'pnl' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.pnlPercent.show && column.key === 'pnl_percent' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.quantity.show && column.key === 'quantity' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.entry_price.show && column.key === 'entry_price' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.exit_price.show && column.key === 'exit_price' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.profit.show && column.key === 'profit' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.cumulative_quantity.show && column.key === 'cumulative_quantity' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {tableColumns.commission.show && column.key === 'commission' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldPositionsSummary)}
                                onClick={() => handleSort(column.key as SortFieldPositionsSummary)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                          </th>
                        )}
                      </Draggable>
                    ))}
                    { provided.placeholder}
                  </tr>
                )}
              </Droppable>
            </thead>
            {sortedPositionsSummury && sortedPositionsSummury.length > 0 && (
              <tbody>
                {sortedPositionsSummury.map((position) => (
                  <tr key={position.id} className="history-item">
                    {columnOrder.map((column) => (
                      <td key={column.key}>
                        {tableColumns.entered_at.show && column.key === 'entered_at' && (
                          <div className={styles.tdWrapper}>
                            {moment(position.entered_at).format('YYYY-MM-DD HH:mm:ss')}
                          </div>
                        )}
                        {tableColumns.instrument.show && column.key === 'instrument' && (
                          <div className={styles.instrumentTd}>
                            {position.instrument.symbol}
                            <BalanceType type={position.trading_type} />
                          </div>
                        )}
                        {tableColumns.side.show && column.key === 'side' && (
                          <div className={className(styles.actionTd, { [styles.sell]: position.side === 'SHORT' })}>
                            {position.side}
                          </div>
                        )}
                        {tableColumns.pnl.show && column.key === 'pnl' && (
                          <div className={className(
                            styles.tdWrapper,
                            { [styles.minus]: +position.pnl < 0 },
                            { [styles.plus]: +position.pnl >= 0 },
                          )}
                          >
                            {!balanceVisible ? position.pnl : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.pnlPercent.show && column.key === 'pnl_percent' && (
                          <div className={className(
                            styles.tdWrapper,
                            { [styles.minus]: +position.pnl_percent < 0 },
                            { [styles.plus]: +position.pnl_percent >= 0 },
                          )}
                          >
                            {position.pnl_percent} %
                          </div>
                        )}
                        {tableColumns.quantity.show && column.key === 'quantity' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible ? toFixedDecimal(position.quantity) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible ? toFixedDecimalPrecision(multiplyValues(position.quantity, position.entry_price), USDT_PRECISION) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.entry_price.show && column.key === 'entry_price' && (
                          <div className={styles.tdWrapper}>
                            {toFixedDecimal(position.entry_price)}
                          </div>
                        )}
                        {tableColumns.exit_price.show && column.key === 'exit_price' && (
                          <div className={styles.tdWrapper}>
                            {position.exit_price ? toFixedDecimal(position.exit_price) : '-'}
                          </div>
                        )}
                        {tableColumns.profit.show && column.key === 'profit' && (
                          <div className={className(
                            styles.tdWrapper,
                            { [styles.minus]: parseFloat(position.profit) < 0 },
                            { [styles.plus]: parseFloat(position.profit) >= 0 },
                          )}
                          >
                            {!balanceVisible ? toFixedDecimalPrecision(position.profit) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.cumulative_quantity.show && column.key === 'cumulative_quantity' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible ? toFixedDecimalPrecision(position.cumulative_quantity) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {tableColumns.commission.show && column.key === 'commission' && (
                          <div className={styles.tdWrapper}>
                            {!balanceVisible ? toFixedDecimal(position.commission) : HIDDEN_BALANCES}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </DragDropContext>

        {status === EStatus.loading && sortedPositionsSummury && sortedPositionsSummury.length === 0 && (
          <div className={styles.loadingWrapper}>
            <LoadingOverlay
              className="loader"
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
        {status === EStatus.success && sortedPositionsSummury && sortedPositionsSummury.length === 0 && (
          <Empty maxWidthText="170px">
            {t('no-history-for-selected-period')}
          </Empty>
        )}
        {status === EStatus.rejected && (
          <Empty maxWidthText="170px">
            {t('no-history-for-selected-period')}
          </Empty>
        )}
        {status === EStatus.loading && sortedPositionsSummury && sortedPositionsSummury.length > 0 && (
          <div className={styles.loadingItemWrapper}>
            <LoadingOverlay
              className={`loader ${styles.loader}`}
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
      </div>
      <div />
    </section>
  );
});

export default PositionsSummuryTable;
