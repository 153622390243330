import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { saveTokensLocalStorage } from 'src/shared/libs/helpers/save-tokens';
import { Nullable, SendTypeCode } from 'src/shared/types/global-types';
import { SMS_TYPES, START_PAGE, TIMER_SEND_SMS } from 'src/shared/constants/constants';
import { handleSupportTelegram } from 'src/shared/libs/helpers/helper.lib';
import React, {
  useState, useEffect, FormEventHandler, useLayoutEffect,
} from 'react';
import Hint from 'src/shared/ui/help/hint';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputCode from 'src/shared/ui/input/input-code/input-code';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import InputAction from 'src/pages/auth/components/inputs-auth/input-action';
import VerificationIssues from 'src/entities/verification-issues/verification-issues';
import AuthService, { SendCodeLogin } from 'src/services/auth.service';
import { Login } from '../../model/types';
import { LoginForm } from '../auth-form/auth-form';
import { setAuth, setSessionId } from '../../model/slice';
import { selectSendCodeTypeVisible } from '../../model/selectors';
import './login-verification-form.scss';

interface IVerificationForm {
  methods: string[]
  isVisible: boolean
  verificationId: number
    userData: Nullable<LoginForm>
}

const LoginVerificationForm = ({
  methods, isVisible, verificationId, userData,
} :IVerificationForm) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();

  const [codeGA, setCodeGA] = useState('');
  const [codePhone, setCodePhone] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSmsType, setCurrentSmsType] = useState<Nullable<SendTypeCode>>(null);

  const [methodsList, setMethodsList] = useState(methods);

  const sendCodeTypeVisible = useSelector(selectSendCodeTypeVisible);
  const formElements: React.ReactNode[] = [];

  const [showSelectTypes, setShowSelectTypes] = useState(false);

  const handleSendCode = async (type?: string) => {
    if (!userData) return;

    const sendCodeData: SendCodeLogin = {
      ...userData,
      verification_id: verificationId,
    };

    if (type === 'whatsapp') {
      const { data } = await AuthService.sendCodeWhatsAppLogin(sendCodeData);

      if (data) {
        setAlertMessage('code_sent', 'success');
      } else {
        setAlertMessage('too many attempts 2', 'warning');
      }
      return;
    }

    if (type === 'phone') {
      const { data } = await AuthService.sendCodePhoneLogin(sendCodeData);

      if (data) {
        setAlertMessage('code_sent', 'success');
      } else {
        setAlertMessage('code_send_error', 'error');
      }
      return;
    }

    if (methodsList.includes('whatsapp')) {
      const { data } = await AuthService.sendCodeWhatsAppLogin(sendCodeData);

      if (data) {
        setAlertMessage('code_sent', 'success');
      } else {
        setAlertMessage('code_send_error', 'error');
      }
    }

    if (methodsList.includes('phone')) {
      const { data } = await AuthService.sendCodePhoneLogin(sendCodeData);

      if (data) {
        setAlertMessage('code_sent', 'success');
      } else {
        setAlertMessage('code_send_error', 'error');
      }
    }
  };

  const handleOnSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (!userData || !currentSmsType) return;

    if (methodsList.includes('ga') && !codeGA) {
      setCodeError(true);
      return;
    }

    if (methodsList.includes('phone') && !codePhone) {
      setCodeError(true);
      return;
    }

    if (methodsList.includes('whatsapp') && !codePhone) {
      setCodeError(true);
      return;
    }

    setCodeError(false);
    setLoading(true);

    let authData: Login = {
      email: userData.email,
      password: userData.password,
      verification_id: verificationId,
      method_sms: currentSmsType.type,
    };

    if (codePhone.length && methodsList.includes('phone')) {
      authData = {
        ...authData,
        phone_verification_code: Number(codePhone),
      };
    }
    if (codePhone.length && methodsList.includes('whatsapp')) {
      authData = {
        ...authData,
        whatsapp_verification_code: Number(codePhone),
      };
    }
    if (codeGA.length) {
      authData = {
        ...authData,
        ga_verification_code: codeGA,
      };
    }

    try {
      const responseAuth = await AuthService.login(authData);

      if (responseAuth.session_id) dispatch(setSessionId(responseAuth.session_id));

      if (responseAuth.access_token && responseAuth.refresh_token) {
        saveTokensLocalStorage(responseAuth);
        dispatch(setAuth(true));
        navigate(START_PAGE);
      }
    } catch (error: unknown) {
      if (typeof error === 'string') {
        if (error === 'INVALID_VERIFICATION_CODE') {
          setCodeError(true);
        } else if (error === 'TOO_MANY_REQUESTS') {
          setAlertMessage('too many attempts 2', 'warning');
        } else {
          setAlertMessage(error, 'error');
        }
      } else {
        setAlertMessage('internal error', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmitOnlyGA = async () => {
    if (!userData) return;

    if (methodsList.includes('ga') && !codeGA) {
      setCodeError(true);
      return;
    }

    setCodeError(false);
    setLoading(true);

    let authData: Login = {
      email: userData.email,
      password: userData.password,
      verification_id: verificationId,
    };

    if (codeGA.length) {
      authData = {
        ...authData,
        ga_verification_code: codeGA,
      };
    }

    try {
      const responseAuth = await AuthService.login(authData);

      if (responseAuth.session_id) dispatch(setSessionId(responseAuth.session_id));

      if (responseAuth.access_token && responseAuth.refresh_token) {
        saveTokensLocalStorage(responseAuth);
        dispatch(setAuth(true));
        navigate(START_PAGE);
      }

      if (typeof responseAuth === 'boolean' && responseAuth === false) {
        setCodeError(true);
      }
    } catch (error: unknown) {
      if (typeof error === 'string') {
        if (error === 'INVALID_VERIFICATION_CODE') {
          setCodeError(true);
        } else if (error === 'TOO_MANY_REQUESTS') {
          setAlertMessage(`${t('too_many_attempts')} ${TIMER_SEND_SMS} ${t('seconds')}`, 'warning');
        } else {
          setAlertMessage(error, 'error');
        }
      } else {
        setAlertMessage('timeout_error', 'error');
        navigate('login');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSmsType = async (codeType: SendTypeCode) => {
    if (codeType.type === currentSmsType?.type) return;

    setCodePhone('');
    setCurrentSmsType(codeType);
    setMethodsList([codeType.type]);
    handleSendCode(codeType.type);
  };

  useLayoutEffect(() => {
    setMethodsList(methods);
  }, [methods]);

  useEffect(() => {
    const [sendWhatsapp, sendSms] = SMS_TYPES;

    if (methodsList.includes('whatsapp')) {
      setCurrentSmsType(sendWhatsapp);
      setShowSelectTypes(true);
    }

    if (methodsList.includes('phone')) {
      setCurrentSmsType(sendSms);
      setShowSelectTypes(true);
    }
  }, [methodsList]);

  useEffect(() => {
    if (methodsList) {
      const onlyGa = methodsList.every((method) => method === 'ga') && methodsList.includes('ga');
      if (onlyGa) {
        if (codeGA.length === 6) {
          handleOnSubmitOnlyGA();
        }
      }
    }
  }, [codeGA, methodsList]);

  if (!isVisible) return null;

  if (methodsList.includes('phone')) {
    formElements.push(
      <div key="sms-input" className="verification-input">
        <InputAction
          label={t('sms code')}
          placeholder={t('code')}
          actionTitle={t('send code')}
          actionActive
          onClick={handleSendCode}
          type="number"
          error={codeError}
          {...{
            value: codePhone,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
          }}
        />
      </div>,
    );
  }

  if (methodsList.includes('whatsapp')) {
    formElements.push(
      <div key="whatsapp-input" className="verification-input">
        <InputAction
          label={t('whatsapp code')}
          placeholder={t('code')}
          actionTitle={t('send code')}
          actionActive
          onClick={handleSendCode}
          error={codeError}
          {...{
            value: codePhone,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
          }}
        />
      </div>,
    );
  }

  if (methodsList.includes('ga')) {
    formElements.push(
      <div key="ga-input" className="verification-input">
        <InputForm title={t('google authenticator code')}>
          <InputCode focus error={codeError} onChange={setCodeGA} />
        </InputForm>
      </div>,
    );
  }

  formElements.push(
    <div key="modal-hint-whatsapp" className="verification-input">
      <Hint
        hintOpen
        position="bottom-start"
        text={t('authentication_error')}
        hintText={<VerificationIssues onClick={handleSupportTelegram} />}
      />
    </div>,
  );

  formElements.push(
    <div key="verify-modal-action" className="verify-modal-action">
      <Button disabled={loading} onClick={() => navigate('/login')} background="gray">{t('back')}</Button>
      <Button
        type="submit"
        background="green"
        loading={loading}
        disabled={loading}
      >{t('сontinue')}
      </Button>
    </div>,
  );

  return (
    <div className="login-verification">
      { showSelectTypes && (
        <>
          <InputForm title={t('notification-sending')}>
            <Select
              classNameSelect={!sendCodeTypeVisible ? 'enable-select' : ''}
              disabledSelect={sendCodeTypeVisible}
              isActiveSearch={false}
              timerId="changeSmsTypeTimer"
              isSelectValue={<span className="sms-type-current">{currentSmsType?.name || t('not_selected') }</span>}
            >
              { SMS_TYPES.map((element) => (
                <span
                  key={element.type}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleChangeSmsType(element)}
                  onKeyDown={() => handleChangeSmsType(element)}
                >{element.name}
                </span>
              )) }
            </Select>
          </InputForm>

          <Hint text={t('resend_code_option')} />

        </>
      )}

      <form className="login-verification-form-wrapper" onSubmit={handleOnSubmit}>{formElements}</form>
    </div>
  );
};

export default LoginVerificationForm;
