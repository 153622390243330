import { useDisclosure } from '@mantine/hooks';
import {
  Indicator, Modal, Tooltip,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';

import { socket } from 'src/shared/api/websocket.instance';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { clearStoreRedux } from 'src/shared/libs/helpers/helper.lib';
import { hideAccountBalance } from 'src/pages/settings/model/slice';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { fetchChangeTradeSettings } from 'src/pages/settings/model/thunks';

import { ReactComponent as BellIconSvg } from 'src/shared/assets/images/header/bell.svg';
import { ReactComponent as MoonIconSvg } from 'src/shared/assets/images/header/moon.svg';
import { ReactComponent as LightIconSvg } from 'src/shared/assets/images/header/light.svg';
import { ReactComponent as ViewIconSvg } from 'src/shared/assets/images/header/view.svg';
import { ReactComponent as EyeClosedSvg } from 'src/shared/assets/images/auth/eye-closed.svg';
import { ReactComponent as LogOutSvg } from 'src/shared/assets/images/header/log-out.svg';
import { ReactComponent as LogotypeIconSvg } from 'src/shared/assets/images/header/logotype-theme.svg';

import useMobile from 'src/shared/libs/hooks/useMobile';
import LanguageSelection from 'src/entities/language-selection/language-selection';
import ExitModalContent from 'src/entities/exit-modal-content/exit-modal-content';

import { selectNotifications } from '../notifications/model/selectors';
import './header-diary.scss';

interface IHeaderDiaryProps {
  setShownNotifications: (value: boolean) => void;
}

const HeaderDiary = ({ setShownNotifications }: IHeaderDiaryProps) => {
  const isMobile = useMobile();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();

  const [openedLogout, { open: openLogoutModal, close: closeLogoutModal }] = useDisclosure(false);

  const [isAnimating, setIsAnimating] = useState(false);
  const [isAnimatingEye, setIsAnimatingEye] = useState(false);

  const { notifications } = useSelector(selectNotifications);
  const balanceVisible = useSelector(selectHiddenBalance);

  const readStatus: boolean = notifications ? notifications.items.some((message) => !message.read) : false;

  const handleSetTheme = () => {
    if (theme === EThemeMode.LIGHT) setTheme(EThemeMode.DARK);
    if (theme === EThemeMode.DARK) setTheme(EThemeMode.LIGHT);

    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
    }, 200);
  };

  const handleHiddenBalances = async () => {
    const visible = !balanceVisible;

    const { payload } = await dispatch(fetchChangeTradeSettings({ hide_account_balance: visible }));

    if (payload) {
      dispatch(hideAccountBalance(visible));
      setIsAnimatingEye(true);

      setTimeout(() => {
        setIsAnimatingEye(false);
      }, 200);
    }
  };

  const handleLogout = useCallback(() => {
    try {
      socket?.close();
    } catch (error) {
      console.debug('Error while closing the WebSocket:', error);
    }

    localStorage.clear();

    clearStoreRedux();
  }, []);

  return (
    <header className="header-diary">
      <LogotypeIconSvg className="header-diary_logo" />
      <nav>
        <Link to="/">{t('personalCabinet')}</Link>
        <Link to="/screener">{t('screener')}</Link>
        <Link to="/algo-trading">{t('algoTrading')}</Link>
        <Link to="/alerts">{t('alerts')}</Link>
        <Link to="/news">{t('news')}</Link>
      </nav>

      <div className="page-customisations">
        <LanguageSelection position="relative" />

        <Tooltip disabled={isMobile} zIndex={3001} withArrow label={theme === EThemeMode.LIGHT ? t('dark theme') : t('light theme')} position="bottom" offset={9}>
          <button className={`theme-button ${isAnimating ? 'animating' : ''}`} onClick={handleSetTheme}>
            {theme === EThemeMode.LIGHT && <MoonIconSvg />}
            {theme === EThemeMode.DARK && <LightIconSvg className="light-icon" />}
          </button>

        </Tooltip>

        <Tooltip disabled={isMobile} zIndex={3001} withArrow className={`eye-button ${isAnimatingEye ? 'animating' : ''}`} label={balanceVisible ? t('show balances') : 'hide balances'} position="bottom" offset={9}>
          <button onClick={handleHiddenBalances}>
            { balanceVisible ? <ViewIconSvg /> : <EyeClosedSvg />}
          </button>
        </Tooltip>

        <Indicator disabled={!readStatus} className="indicator" processing color="red" size={10}>
          <Tooltip disabled={isMobile} zIndex={3001} withArrow label={t('notifications')} position="bottom" offset={9}>
            <button
              className="bell"
              onClick={() => setShownNotifications(true)}
            >
              <BellIconSvg />
            </button>
          </Tooltip>
        </Indicator>

        <Tooltip disabled={isMobile} zIndex={3001} withArrow label={t('exit')} position="bottom" offset={9}>
          <button onClick={openLogoutModal}>
            <LogOutSvg onClick={openLogoutModal} />
          </button>
        </Tooltip>
      </div>

      <Modal
        title={<span>{t('confirm logout')}</span>}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="md"
        opened={openedLogout}
        onClose={closeLogoutModal}
        className="modal-custom-overflow"
      >
        <ExitModalContent logOut={handleLogout} onClose={closeLogoutModal} />
      </Modal>
    </header>
  );
};

export default memo(HeaderDiary);
