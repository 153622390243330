import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { Login, UserRegistration } from 'src/pages/auth/login/model/types';
import { Tokens } from 'src/shared/types/global-types';
import { AxiosError } from 'axios';
import i18next from 'i18next';

export type RegistrationStepOne = {
  email: string,
  recaptcha_token: string
  invite_code?: string
}

export type RegistrationStepTwo = {
  email: string,
  email_verification_code: number
}

export type SendCodeData = {
  phone_number: string,
  email: string
}
export type RegistrationResponse = {
  verification_id: number
  session_id: string
} & Tokens

export type SendCodeLogin = {
  email: string
  password: string
  verification_id: number
}

export type LoginReponse = {
  access_token: string
  refresh_token: string
  session_id: string
  methods?: string[]
  verification_id?: number
}

class AuthService {
  static async login(loginData: Login) {
    try {
      const response = await $api.post<LoginReponse>(ROUTE_PATH.login.login, loginData);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) throw error.response.data.detail;
      throw error;
    }
  }

  static async registrationStepOne(userData: RegistrationStepOne) {
    try {
      const response = await $api.post(ROUTE_PATH.registration.step1, userData);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) throw error.response.data.detail;
      throw error;
    }
  }

  static async registrationStepTwo(userData: RegistrationStepTwo) {
    const response = await $api.post(ROUTE_PATH.registration.step2, userData);
    return response;
  }

  static async registration(registerData: UserRegistration) {
    const response = await $api.post<RegistrationResponse>(ROUTE_PATH.registration.registration, registerData);
    return response;
  }

  static async sendCodeEmail(email: string) {
    const response = await $api.post(ROUTE_PATH.registration.sendCodeEmail, { email, language: i18next.language });
    return response;
  }

  static async sendCodePhone(data: SendCodeData) {
    const response = await $api.post(ROUTE_PATH.registration.sendCodePhone, { ...data, language: i18next.language });
    return response;
  }

  static async sendCodeWhatsApp(data: SendCodeData) {
    const response = await $api.post(ROUTE_PATH.registration.sendCodeWhatsApp, { ...data, language: i18next.language });
    return response;
  }

  static async sendCodePhoneLogin(params: SendCodeLogin) {
    const response = await $api.post(ROUTE_PATH.login.sendCodePhone, { email: params.email, password: params.password }, {
      headers: {
        'verification-id': params.verification_id,
      },
    });
    return response;
  }

  static async sendCodeWhatsAppLogin(params: SendCodeLogin) {
    const response = await $api.post(ROUTE_PATH.login.sendCodeWhatsApp, { email: params.email, password: params.password }, {
      headers: {
        'verification-id': params.verification_id,
      },
    });
    return response;
  }
}

export default AuthService;
